import { useMemo, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import useConfig from './useConfig';

export const useUser = () => {
  const { user, setUser, logout, setPreviousUrl, previousUrl } =
    useContext(UserContext) || {};
  const { configOptions } = useConfig();

  let userDisplayName = user.username;
  const samlDisplayNameAttribute = configOptions.saml?.usernameSource;

  if (samlDisplayNameAttribute && user.attributes?.[samlDisplayNameAttribute]) {
    userDisplayName = user.attributes[samlDisplayNameAttribute];
  }

  const roles = useMemo(() => user?.roles || [], [user]);

  const isContentAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-content-admin'),
    [user],
  );

  const isOntologyAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-ontology-admin'),
    [user],
  );

  const isAppAdmin = useMemo(
    () => user?.roles?.includes('4v-nexus-app-admin'),
    [user],
  );

  return {
    user,
    setUser,
    logout,
    setPreviousUrl,
    previousUrl,
    roles,
    isContentAdmin,
    isOntologyAdmin,
    isAppAdmin,
    userDisplayName,
  };
};

export default useUser;
