import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { useStore, useUser } from 'hooks';
import { useEffect, useState } from 'react';

/**
 * Builder for the Matomo Component-- necessary for sending tracker info to Matomo.
 *
 * @param {*} { children } - The child nodes of the React component.
 * @return {*} - a React Component for Matomo-- or an empty component if Matomo is not needed.
 */
export function MatomoInstance({ children }) {
  const { store } = useStore();
  const { user } = useUser();
  const [matomoInstance, setMatomoInstance] = useState(<>{children}</>);

  // create the Matomo instance with the publicAppConfig properties
  //   whenever they are changed.
  useEffect(() => {
    // console.log('store', { store });
    // console.log('user', { user });
    if ([true, 'true'].includes(store?.config?.matomo?.enabled)) {
      console.log(`Matomo is enabled.`);
      const value = createMatomoInstance(store.config.matomo, user?.username);
      setMatomoInstance(
        <MatomoProvider value={value}>{children}</MatomoProvider>,
      );
    } else {
      // When Matomo is set to be disabled, we want to make this an
      //   empty component (<></>) carrying its children, rather than an empty value attribute.
      // Completely exclude the Matomo process if prompted to-- or if Matomo's settings
      //   aren't available yet.
      console.log('Matomo is disabled.');
      setMatomoInstance(<>{children}</>);
    }
  }, [store.config.matomo, user?.username, children]);

  return matomoInstance;
}

/**
 * Creates the Matomo properties necessary to run a React Matomo component.
 *
 * @param {*} matomoConfig - the 'matomo' property section of our 'store' context. Configures the Matomo component instance. *Required.*
 * @param {String} username - the username of the user making the search. *Required.*
 * @return {Object}
 */
function createMatomoInstance(matomoConfig, username) {
  const {
    matomoUrlBase,
    trackerUrl,
    srcUrl,
    protocol = 'http',
    siteId = 1,
  } = matomoConfig;
  const matomoOpts = {
    // TODO: set to HTTPS-- Matomo has issues connecting via a SSL connection.
    urlBase: `${protocol}://${matomoUrlBase}`,
    // NOTE: siteId refers to the list of sites Matomo is tracking.
    //   Because of this value, 4V Nexus must be the FIRST tracked site on the Matomo instance.
    siteId,
    userId: username,

    // When matomoConfig.enabled is false, make this true, just to be safe.
    //disabled: matomoConfig.enabled === 'false', // optional, false by default. Makes all tracking calls no-ops if set to true.

    configurations: {
      // any valid matomo configuration, all below are optional
      //disableCookies: true,
      // TODO: Was originally 'true', but can't use until this is configured to use HTTPS
      //setSecureCookie: false,
      setRequestMethod: 'POST',
    },
  };
  if (trackerUrl) matomoOpts.trackerUrl = `${protocol}://${trackerUrl}`;
  if (srcUrl) matomoOpts.srcUrl = `${protocol}://${srcUrl}`;
  return createInstance(matomoOpts);
}

/**
 * Used to track 4V Nexus search results & send results directly to Matomo.
 *
 * @export
 * @param {*} trackSiteSearch - Site Search tracker function. Must be passed externally because it requires a component to access.
 * @param {Object} searchResponse - JSON detailing the results of the search.
 */
export function matomoTrackSiteSearch(trackSiteSearch, searchResponse) {
  const highestCategory = searchResponse.facets.concept?.facetValues[0]?.name;
  trackSiteSearch({
    keyword: searchResponse.qtext ?? '(empty)',
    // TODO: It might make more sense to use the most reported concept (or facet, if easier) in the search.
    //   as the category. Unfortunately Matomo doesn't allow yout o secify more than one category (though it will take an array anyways)
    category: highestCategory || 'N/A',
    count: searchResponse.total,
    href: window.location.pathname,
  });
  console.log(
    `Matomo: logged search '${searchResponse?.qtext}' with ${searchResponse.total} results.`,
  );
}

/**
 * Used to track specific events in 4v Nexus and send them to Matomo
 * ** CURRENTLY OUTDATED. ** This was originally used to track site searches, but did so incorrectly.
 *    However, this function will be revamped and used later to track the use of specific UI components.
 *    This is mostly left here as a reminder to that.
 *
 * @export
 * @param {*} trackEvent - Event tracker function. Must be passed externally because it requires a component to access.
 * @param {Object} searchResponse - JSON detailing the results of the search.
 */
export function matomoTrackEvent(trackEvent, searchResponse) {
  trackEvent({
    action: 'trackSiteSearch',
    category: '4V Nexus Search',

    // TODO: There are values to be passed into an HTTPS Tracker request which indicates to Matomo
    //   that this is a Site Search track. However, for some reason, I can't seem to find where the
    //   options are for the thing. Matomo doesn't really have a concise way of showing you when a
    //   tracker comes back as a Stie Search, but it's not collecting any Site Search data.
    search: searchResponse.qtext,
    // TODO: It might make more sense to use the most reported concept (or facet, if easier) in the search.
    //   as the category.
    search_cat: 'SOMETHING',
    search_count: searchResponse.total,

    name: searchResponse.qtext, // optional
    value: searchResponse.total, // should be changed to something of value later
    // the URL of the application where the search is taking place
    href: window.location.pathname,
    customDimensions: [
      {
        // TODO: Must investiagte if possible, but if Matomo is able to take JSONs in this, we might be able to
        //   pass a search's most commonly-used concepts through a single custom dimension.
        id: 1,
        value: 'searched',
      },
    ], // optional
  });
}
